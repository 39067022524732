import "./index.css"

export const flowData = [
    {
        style: "nav-icon icon3",
        type: "customInput",
        text: "Start",
        color: "#ffffff",
        title: "Input Node",
        imageClass: "nav-image-1"
    },
    {
        style: "nav-icon icon4",
        type: "decision",
        text: "Decision",
        color: "#ffffff",
        title: "Decision Node",
        imageClass: "nav-image-2"

    },
    {
        style: "nav-icon icon5",
        type: "customFunction",
        text: "Action",
        color: "#ffffff",
        title: "Execute Node",
        imageClass: "nav-image-3"

    },
    {
        style: "nav-icon icon1",
        type: "circle",
        text: "Circle",
        color: "#ffffff",
        title: "Circle Node",
        imageClass: "nav-image-4"

    },
    {
        style: "nav-icon icon2",
        type: "customOutput",
        text: "End",
        color: "#ffffff",
        title: "Output Node",
        imageClass: "nav-image-5"

    },
    // {
    //     style: "bg-indigo-400 w-15 my-2 py-2 mx-2 rounded-md",
    //     type: "customOutput",
    //     text: "OUTPUT_2",
    //     color: "#5c6bc0",
    //     title: "Drag this OUTPUT_2"
    // },
    // {
    //     style: "bg-blue-200 w-15 my-2 py-2 mx-2 rounded-md cursor-grab",
    //     type: "customInput",
    //     text: "INPUT_2",
    //     color: "#90caf9",
    //     title: "Drag this INPUT_2"
    // },
    // {
    //     style: "bg-teal-400 w-15 my-2 py-2 mx-2 rounded-md cursor-grab",
    //     type: "customFunction",
    //     text: "NODE_EXEC_2",
    //     color: "#26a69a",
    //     title: "Drag this NODE_EXEC_2"

    // },
];