import "../index";
import { useState } from "react";
import FilterItems from "./FlowComponents/FilterItems";
import { Box } from "@mui/system";
import { Divider, Tooltip } from "@mui/material";
import { flowData } from "../assets/FlowData";

const Sidebar = () => {
  const onDragStart = (event, nodeType, label, color) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/reactflow/label", label);
    event.dataTransfer.setData("application/reactflow/color", color);
    event.dataTransfer.effectAllowed = "move";
  };
  const menuItems = [...new Set(flowData.map((val) => val.type))];
  const [item, setItem] = useState(flowData);
  const filterItem = (curcat) => {
    const newItem = flowData.filter((newVal) => {
      return newVal.type === curcat;
    });
    setItem(newItem);
  };

  return (
    <div id="sidebar" className="static" >
     
      <Box
      // backgroundColor="white"
      height={"100%"}
        display="flex"
        flexDirection="row"
        justifyContent={`start`}
        alignItems={`center`}
        
      >
        {item.map((object, key) => (
          <Tooltip key={key} title={object?.title} placement="bottom" arrow>
            <Box
              draggable
              className={object.style}
              onDragStart={(event) =>
                onDragStart(event, object.type, object.text, object.color)
              }
            >
              <Box key={key}>
                {/* {object.text} */}
              </Box>
              
            </Box>

          </Tooltip>
        ))}
      </Box>
    </div>
  );
};


export default Sidebar;