import { Handle, Position } from "react-flow-renderer";
const DynInputHandle = ({ idx }) => {
  return (
    <Handle
      className="centerNodePoint"
      type={"source"}
      id={`source${idx}`}
      position={Position.Bottom}
      style={{ left: 10 + idx * 20, height: "10px", width: "10px", background: "#9D8FFF"
      }}
    />
  );
};
export default DynInputHandle;
