import { useState } from "react";
import DynInputHandle from "../Handler/DynInputHandle";
const CustomInputNode = ({ data }, props) => {
  const [outputcount, setOutputCount] = useState(1);

  return (
    <div className="rounded-xl centerNodeText">
      <div className="rounded-xl py-1 rounded-md">
        {data.label}
        
      </div>
      <div>
        {Array(outputcount)
          .fill(null)
          .map((_, i) => (
            <DynInputHandle key={i} idx={i} />
          ))}
      </div>
    </div>
  );
};
export default CustomInputNode;
