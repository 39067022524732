import {
  ReactFlowProvider
} from "react-flow-renderer";
import { FlowCanvas } from './Pages';

function App() {
  return (
    <ReactFlowProvider>
        <FlowCanvas />
    </ReactFlowProvider>
  );
}

export default App;
