import React,{useState}from "react";
import { Handle } from "react-flow-renderer";
import DynOutputHandle from "../Handler/DynOutputHandle";
import DynInputHandle from "../Handler/DynInputHandle";

const CircleNode = ({ data }) => {

  const [outputcount, setOutputCount] = useState(1);
  const [inputcount, setInputCount] = useState(1);

  return (
    <div className="circleNode centerNodeText">

        <div>
          {Array(outputcount)
            .fill(null)
            .map((_, i) => (
              <DynOutputHandle key={i} idx={i} />
            ))}
        </div>


        {data?.label}


        <div>
          {Array(inputcount)
            .fill(null)
            .map((_, i) => (
              <DynInputHandle key={i} idx={i} />
            ))}
        </div>

    </div>
  );
};
export default CircleNode;
